import Evopayment from './Evopayment.vue';

export default {
  component: Evopayment,
  settingsSchema: {
    fields: [
      {
        type: 'text',
        label: 'URL',
        model: 'url'
      },

      {
        type: 'text',
        label: 'Secret Key',
        model: 'secretKey'
      },

      {
        type: 'text',
        label: 'MerchantId',
        model: 'merchant'
      },

     /*  {
        type: 'select',
        label: 'Currency',
        model: 'currency',
        default: 'MXN',
        options: [
          {
            text: 'Peso mexicano (MXN)',
            value: 'MXN'
          },
          {
            text: 'Dólar Estadounidense (USD)',
            value: 'USD'
          },
        ]
      } */
    ]
  }
}