<template>
	<div class="EcommerceProviderEvopayment">
		<button class="ui-button --main" type="button" @click="openPopup" :disabled="hasErrors">
			Pagar {{ $cr(payment.value, payment.currency) }}
		</button>
		<div> <small>Vía Evopayment</small> </div>
		<div id="embed-target"></div>
		
		<!-- Errores de la pasarela -->
		<div class="is_error" v-for="(error, i) in errors" :key="i">
			<div :class="'type-'+error.type">
				<ui-icon size="22" :value="error.icon" color="inherit"></ui-icon>
				<span class="title"> {{error.type}} </span>
			</div>
			<span class="info-msg">{{error.msg}}</span>
		</div>
	</div>
</template>

<script>
import { useI18n } from '@/modules/i18n';
import * as popup from '../../helpers/popupWindow.js';
import { UiIcon } from '@/modules/ui/components';
import { useApi } from "@/modules/api";

export default {
	name: 'EcommerceProviderEvopayment',
	mixins: [useI18n, useApi],
	components: { UiIcon },

	props: {
		payment: {
			type: Object,
			required: false,
		},

		transaction: {
			type: Object,
			required: false,
		},
	},

	data() {
		return {
			isDone: false,
			errors: [],
			started: this.payment.status == 'waiting' ? true : false,
		};
	},

	computed: {
		hasErrors() {
			return this.errors.length > 0;
		}
	},

	mounted() {
		this.validateData();
	},

	methods: {
		validateData() {
			let flag = false;
			
			// Se verifica que la transacción sea realizada por un usuario valido del sistema, no por "system"
			if (this.transaction.payer == "system") {
				this.errors.push({type: "warning", icon: "g:report", msg: "Not valid user"});
				flag = true;
			}

			// Se revisa si hay errores en el consumo del API de Evopayment generando el link de pago
			if (this.transaction.message) {
				let res = JSON.parse(this.transaction?.message);
				let errors = "";

				if (typeof res === 'object' && res !== null) {
					errors = this.getErrorInfo(res);
					errors.forEach(element => {
						this.errors.push({type: "error", icon: "g:error_outline", msg: element});
					})
				} else {
					this.errors.push({type: "error", icon: "g:error_outline", msg: res});
				}
				flag = true;
			}

			// Se verifica que el valor a pagar sea mayor que 0
			if (this.transaction.value <= 0 ) {
				this.errors.push({type: "warning", icon: "g:report", msg: "Invalid value to pay: " + this.transaction.value});
				flag = true;
			}

			// Se verifica que traiga un session id 
			 if (this.transaction.clientData && !this.transaction.clientData.session) {
				this.errors.push({type: "warning", icon: "g:report", msg: "No session found to Pay"});
				flag = true;
			} 

			if (flag) {
				return;
			}
		},

		getErrorInfo(obj, r = [], Item = null) {
			Object.keys(obj).forEach(key => {
				const value = obj[key];
				if (typeof value !== 'object') {
					if (value && Item) {
						r.push(Item + ": " + value);
					}
				} else if (typeof value === 'object') {
					this.getErrorInfo(value, r, key);
				}
			});

			return r;
		},

		async openPopup() {
			this.isDone = false;
			if (!this.hasErrors) {
				this.started = true;
				this.$emit('send', this.transaction);
				var host = this.$httpClient.host;
				host = host.replace(".api", "");
				popup.open(
					host+'/ecommerce/evopayment'+'?session='+this.transaction.clientData.session+'&transactionId='+this.transaction.id,
					'Evopayment',
					'height=768,width=1024,resizable=yes,toolbar=no,status=no,location=no',
					this.onPopupMessage,
					this.onPopupClose
				);
			}
		},

		onPopupMessage(message) {
			this.isDone = true;
			if (message && typeof message.ok != 'undefined') {
				this.result = message.ok;
				if (this.result) {
					this.$emit('success', message);
				} else {
					this.$emit('error', message);
				}
			}
		},

		onPopupClose() {
			if (!this.isDone) {
				this.$emit('error', {warning: 'transaction without finish'});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.is_error {
	line-height: 1rem;
    align-items: center;
    min-width: 200px;
	width: 300px;
    left: 0;
    margin: -1px 0;
    z-index: 10;
    padding: 10px 5px 10px 10px;
    background: rgba(255,255,255,.9);
	border-left: 5px solid #78909c;
	margin-top: 8px;
	box-shadow: rgba(149, 157, 165, 0.20) 0px 8px 24px;

	.type-error {
		display: flex;
		align-items: center;
		width: 100%;
		padding-bottom: 10px;
		color: #f44336;
	}
	.type-warning {
		display: flex;
		align-items: center;
		width: 100%;
		padding-bottom: 10px;
		color: rgb(8, 70, 163);
	}
	.title {
		padding-left: 5px;
		font-weight: 400;
		font-size: 16px;
	}
}
.info-msg {
	position: relative;
	display: inline-block;
	width: 100%;
}
</style>